<template>
    <div class="modal-overlay-canscroll" style="position: absolute; top: 10px; width: 100%; ">  <!-- not sure why but the height: 100% allows modal to center in the page otherwise it goes to top -->
      <div class="modal" style="padding-top: 5vh; padding-bottom: 5vh;"> 

        <table width=100% border=0 style="padding-left: 2.5vh; padding-right: 2.5vh">
          <tr>
            <td>
              <table width="100%" border=0 s>
                <tr>
                  <td width="30%">
                        <span class="primary-general-text divl" style="display: block; line-height: 15px;">&nbsp;</span> 
                  </td>
                  <td width="40%">
                    <img class="tulogo" style="height: 6vh; padding-bottom: 1vh" alt="ThankU logo" src="../assets/tutxtlogo.png">
                  </td>
                  <td width="30%">
                    <div class=divr>
                 
                      <button @click="doShowHome" class="text-only-button cancel"> <img class="tulogo" style="height: 4vh;" alt="close" src="../assets/greycross.png"></button>
                    </div>
                  </td>

                </tr>
              </table>
              
            </td>
          </tr>
         
           <tr>
            <td style="padding-top: 1vh; padding-bottom: 1vh">              
              <span class="lt-blue" style="font-size: 14px; ">
                  <p class="MsoNormal" style="margin-bottom: 0.0001pt; line-height: normal;"><b><span style="font-size: 13.5pt; font-family: &quot;Arial&quot;,sans-serif; color: rgb(94, 144, 227);">About thankU</span></b></p>
                  <br>
                  ThankU Cashless Tipping is a web app (no download required) for giving tips with your phone when you don't have cash. <p></p>ThankU is widely used in hair and beauty salons in the UK and will soon be rolling out to hotels and other hospitality sectors in the UK and USA.<p></p>
                  To use ThankU, simply scan the ThankU QR code where you see it displayed - as shown below - the process is incredibly simple and quick. The default payment method is Apple Pay or Google Pay depending on your phone, and if you don't use either, you can simply use your card which will be converted to a secure digital token by our payment processor. for your future use. You have the option to quickly create a ThankU user account (display name to show others, email and password) at the end of the process, but it is not required.
                  <p></p>
                  The payment goes directly to the person you are tipping, not via their employer. 
                  <p></p>

                  <img class="tulogo" style="height: 43vh; padding-bottom: 1vh" alt="ThankU QR code pic 1" src="../assets/pos2croppedsm2.webp">
                  <img class="tulogo" style="height: 29.5vh; padding-bottom: 1vh" alt="ThankU QR code pic 2" src="../assets/countersquaresm2.webp">


                  <p></p>
                  If you are not able to scan the ThankU QR code, for example because you already left the hair salon where the person you want to tip works, you can still tip them by going to the ThankU home page and tapping the 'Tip without scanning...' button - you then select from an easily searchable list the business where they work, and then go ahead and tip them as normal.
                  <p></p>
                  You can also tip if they or their business email you a ThankU tip link, for example on a digital receipt.
                             
              </span> 
           </td>
          </tr>
          <tr>
            <td>
              <div class="actionbtn-div" style="padding-top: 1vh">
                <button class="widebtn" style="width: 20%; height: 6vh;"  @click="this.doShowHome()" >Ok</button>
              </div>
            </td>
          </tr>
         
        </table>       
      </div>
    </div>
</template>

<script>
import shared from '../shared.js';

export default {

  methods: {
    
    doShowHome(){
      // /console.log("we're off!");

      let returnJSON = { showHome: true }; 
      this.$emit('return-json', returnJSON);

    },
    doSetMetas(){

      document.title = "About ThankU Cashless Tipping - no download or account creation required";
      document.getElementsByTagName('meta').namedItem('description').setAttribute('content',
      "About ThankU Cashless Tipping - ThankU Cashless Tipping is a web app (no download required) for giving tips with your phone when you don't have cash. ThankU is widely used in hair and beauty salons in the UK and will soon be rolling out to hotels and other hospitality sectors in the UK and USA. To use ThankU, simply scan the ThankU QR code where you see it displayed - as shown below - the process is incredibly simple and quick. The default payment method is Apple Pay or Google Pay depending on your phone, and if you don't use either, you can simply use your card which will be converted to a secure digital token by our payment processor. for your future use. You have the option to quickly create a ThankU user account (display name to show others, email and password) at the end of the process, but it is not required. The payment goes directly to the person you are tipping, not via their employer.") ;
    
    }
    
  },
  created(){
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
    this.doSetMetas();
    shared.saveToUserPath(this.devEnv, "Show about info " + new Date().toISOString(), this.globalPushForwardInterval);
  }
}
</script>

